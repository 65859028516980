<template>
  <span class="sort-by-wrapper">
    <button
      v-on:click="toggleFilters"
      class="uppercase p-2 flex text-blue-700 font-bold underline items-center justify-center"
      :class="{ 'sm:w-96': showFilters }"
    >
      Filter
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 13.0357V20.0357H9.99995V13.0357L2.94995 4.03571H21.0499L14 13.0357Z"
          fill="#0000EE"
        />
      </svg>
    </button>
    <div
      class="bg-white w-full sm:w-96 absolute sm:static left-0"
      v-if="showFilters"
    >
      <!-- <div>
        <div>
          <button
            v-on:click="toggleSection('sortBy')"
            class="border p-1 border-gray-400 w-full bg-white  underline uppercase text-blue-700 flex items-center justify-center"
          >
            Sort By
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="ml-3 w-4"
            >
              <path
                d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                class="fill-current"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="openSections['sortBy']" class="border-b border-gray-400">
          <button
            v-on:click="getChrono"
            class="border p-1 border-gray-400 w-full bg-white  underline uppercase text-left border-t-0 flex items-center justify-between"
          >
            <span>Chronological</span>
            <div
              class="border border-black w-4 h-4 flex items-center justify-center"
            >
              <svg
                v-if="sortByMode == 'chrono'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </div>
          </button>
          <button
            class="border p-1 border-gray-400 w-full bg-white  underline uppercase text-left border-t-0 flex items-center justify-between"
            v-on:click="getToday"
          >
            <span>Today's Games</span>
            <div
              class="border border-black w-4 h-4 flex items-center justify-center"
            >
              <svg
                v-if="sortByMode == 'today'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </div>
          </button>
          <button
            class="border p-1 border-gray-400  border-t-0 flex items-center justify-between  border-b-0 w-full bg-white  underline uppercase text-left"
            v-on:click="getLeagues()"
          >
            <span>League</span>
            <div
              class="border border-black w-4 h-4 flex items-center justify-center"
            >
              <svg
                v-if="sortByMode == 'league'"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-3 h-3"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </div>
          </button>
        </div>
      </div> -->
      <div v-if="sortByMode != 'today'">
        <div>
          <button
            v-on:click="toggleSection('date')"
            class="border p-1 border-gray-400 w-full bg-white underline uppercase text-blue-700 flex items-center justify-center"
          >
            Pick date
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="ml-3 w-4"
            >
              <path
                d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                class="fill-current"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="openSections['date']">
          <div
            class="border border-gray-400 cursor-pointer p-2 border-b-0 text-center uppercase text-gray-400 underline text-xxs border-t-0"
            v-on:click="
              chosenDate = '';
              toggleFilters();
            "
          >
            <span class="flex-1">Reset</span>
          </div>
          <Datepicker
            :inline="true"
            v-model="chosenDate"
            :disabled-dates="disabledDates"
            :monday-first="true"
          />
        </div>
      </div>
      <div>
        <div>
          <button
            v-on:click="toggleSection('league')"
            class="border p-1 border-t-0 border-gray-400 w-full bg-white underline uppercase text-blue-700 flex items-center justify-center"
          >
            Pick league
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="ml-3 w-4"
            >
              <path
                d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                class="fill-current"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="openSections['league']">
          <div
            class="border border-gray-400 cursor-pointer p-2 text-center uppercase text-gray-400 underline text-xxs border-t-0"
            v-on:click="toggleLeagueActive()"
          >
            <span class="flex-1">Reset</span>
          </div>
          <ul class="flex flex-wrap border-gray-400 border-r">
            <li
              class="border border-gray-400 cursor-pointer p-2 text-center uppercase text-blue-700 underline w-1/2 text-xxs border-t-0 border-r-0"
              v-for="(league, key) in leaguesInOrder"
              :key="key"
              v-on:click="toggleLeagueActive(league.title)"
              :class="{
                '!bg-blue-700 !text-white': leaguesActive[league.title],
              }"
            >
              <span class="flex-1">{{ league.title }}</span>
            </li>
          </ul>
          <ul class="flex flex-wrap border-gray-400 border-r">
            <li
              class="border border-gray-400 cursor-pointer p-2 text-center uppercase text-white bg-gray-400 underline w-1/2 text-xxs border-t-0 border-r-0"
              v-on:click="selectAllLeagues()"
            >
              <span class="flex-1">Select All</span>
            </li>
            <li
              class="border border-gray-400 cursor-pointer p-2 text-center border-l-gray-500 uppercase text-white bg-gray-400 underline w-1/2 text-xxs border-t-0 border-r-0"
              v-on:click="applyLeagues()"
            >
              <span class="flex-1">Apply</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div>
          <button
            v-on:click="toggleSection('odds')"
            class="border p-1 border-t-0 border-gray-400 w-full bg-white underline uppercase text-blue-700 flex items-center justify-center"
          >
            Odds
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="ml-3 w-4"
            >
              <path
                d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
                class="fill-current"
              ></path>
            </svg>
          </button>
        </div>
        <div v-if="openSections['odds']" class="flex">
          <button
            :class="{ '!bg-blue-700 !text-white': oddsMode == 'fractional' }"
            v-on:click="fractionalOdds"
            class="border p-1 border-t-0 border-r-0 border-gray-400 w-full bg-white underline uppercase text-blue-700 flex items-center justify-center"
          >
            Fraction
          </button>
          <button
            :class="{ '!bg-blue-700 !text-white': oddsMode == 'decimal' }"
            class="border p-1 border-t-0 border-gray-400 w-full bg-white underline uppercase text-blue-700 flex items-center justify-center"
            v-on:click="decimalOdds"
          >
            Decimal
          </button>
        </div>
      </div>
      <div>
        <div>
          <button
            v-on:click="resetAll()"
            class="border p-1 border-t-0 border-gray-400 bg-gray-200 w-full underline uppercase text-gray-500 flex items-center justify-center"
          >
            Reset all
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="3"
              stroke="currentColor"
              class="w-4 h-4 ml-2 -mt-0.5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
export default {
  name: "FiltersWrapper",
  props: [
    "sortByMode",
    "leaguesForFilters",
    "chosenDateValue",
    "datesForFilters",
    "leaguesToShowData",
  ],
  components: {
    Datepicker,
  },
  data() {
    return {
      openSections: {},
      showFilters: false,
      oddsMode: "fractional",
      leagues: [],
      leaguesActive: {},
      chosenDate: "",
    };
  },
  mounted() {
    this.leagues = this.leaguesForFilters;
    this.chosenDate = this.chosenDateValue;
    this.leaguesActive = this.leaguesToShowData.reduce((obj, item) => {
      obj[item] = true;
      return obj;
    }, {});
  },
  computed: {
    leaguesInOrder() {
      return _.orderBy(this.leagues, ["title"], ["asc"]);
    },
    disabledDates() {
      return {
        customPredictor: (date) => {
          const currentDate = date.toISOString().slice(0, 10);
          if (this.datesForFilters.includes(currentDate)) {
            return false;
          } else {
            return true;
          }
        },
      };
    },
  },
  methods: {
    resetAll() {
      this.openSections = {};
      this.showFilters = false;
      this.oddsMode = "fractional";
      this.leagues = this.leaguesForFilters;
      this.chosenDate = "";
      this.leaguesActive = {};
      this.$emit("leaguesToShow", []);
    },
    selectAllLeagues() {
      _.forEach(this.leagues, (league) => {
        Vue.set(this.leaguesActive, league.title, true);
      });
    },
    applyLeagues() {
      this.$emit("leaguesToShow", Object.keys(this.leaguesActive));
      this.toggleFilters();
    },
    toggleLeagueActive(payload) {
      if (payload) {
        if (this.leaguesActive[payload]) {
          Vue.delete(this.leaguesActive, payload);
        } else {
          Vue.set(this.leaguesActive, payload, true);
        }
      } else {
        this.leaguesActive = {};
        this.$emit("leaguesToShow", []);
        this.toggleFilters();
      }
    },
    toggleSection(payload) {
      Vue.set(this.openSections, payload, !this.openSections[payload]);
      //   this.openSections[payload] = !this.openSections[payload];
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
      this.$emit("bubbleFilters", this.showFilters);
      if (!this.showFilters) {
        this.openSections = {};
      }
    },
    getLeagues() {
      this.$emit("getLeagues");
    },
    getChrono() {
      this.$emit("getChrono");
    },
    getToday() {
      this.$emit("getToday");
    },
    fractionalOdds() {
      this.oddsMode = "fractional";
      this.$emit("fractionalOdds");
    },
    decimalOdds() {
      this.oddsMode = "decimal";
      this.$emit("decimalOdds");
    },
  },
  watch: {
    chosenDate() {
      if (this.chosenDate != "") {
        this.$emit("chosenDate", this.chosenDate);
      } else {
        this.$emit("chosenDate", "");
      }
    },
  },
};
</script>

<style>
.vdp-datepicker__calendar {
  width: 100% !important;
}

.vdp-datepicker__calendar .cell.selected {
  background-color: rgba(29, 78, 216, 1) !important;
  color: #fff !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border-color: rgba(29, 78, 216, 1) !important;
}
</style>
